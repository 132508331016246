// Function to check if a value is empty
function isEmpty(value) {
  return value === null || value === undefined || value === "";
}

// Function to capitalize the first letter of a string
function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Function to generate a random number within a range
function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const formatdate = (d) => {
  if (d !== null) {
    const date = new Date(d);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const result =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " @ " +
      hours +
      ":" +
      minutes +
      ampm;
    return result;
  } else {
    return "NA";
  }
};

const getrandomcoler = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Export the helper functions
export { isEmpty, capitalize, getRandomNumber, formatdate, getrandomcoler};
