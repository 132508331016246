import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

const theme =  createTheme({
	spacing: 8,
	typography: {
		fontFamily: [
            'Arial', 'Helvetica', 'sans-serif'
		].join(','),
		h1: {
			fontSize: '5rem',
			fontFamily: 'Raleway',
		},
		h2: {
			fontSize: '3.5rem',
			fontFamily: 'Open Sans',
			fontStyle: 'bold',
		},
		h3: {
			fontSize: '2.5rem',
			fontFamily: 'Roboto',
		},
	},
	palette: {
		background: {
			default: '#f2f2f2'//green
		},
		primary: {
			main: '#FF5733',//indigo
		},
		secondary: {
			main: '#E769A6',//pink
		},
		error: {
			main: '#D72A2A',//red
		},

		warning: {
			main: '#FC7B09',//orange
		},
		info: {
			main: '#6B7D6A',//gray
		},
		success: {
			main: '#09FE00',//green
		},
		text: {
			primary: '#000000',//black
			secondary: '#000000',//white
		},
		
	},
});


export default theme;
