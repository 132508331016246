import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { post } from "../../../../ApiHelper";
import Layout from "../../../layouts/Layout";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function PushNotificationForm() {
  const [msgAlert, setMsgAlert] = useState({
    message: undefined,
    status: undefined,
  });
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    link: "",
    icon: "",
    scheduled_time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await post(
        `${global.appUrl}/crmapis/savepushnotification`,
        formData
      );
      console.log(response);
      // const response = await axios.post('/api/push-notifications', formData);

      if (response?.message === "success" && response.data) {
        setMsgAlert((prev) => ({
          ...prev,
          message: response.message,
          status: "success",
        }));
      } else {
        setMsgAlert((prev) => ({
          ...prev,
          message: JSON.stringify(response?.data),
          status: "error",
        }));
      }
    } catch (error) {
      console.error("Error saving push notification:", error);
      // Handle error, e.g., show an error message
    }
  };

  return (
    <Layout msgAlert={msgAlert}>
      <Container maxWidth="sm">
        <Typography variant="h5" align="center" gutterBottom>
          Create Push Notification
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Link"
                variant="outlined"
                fullWidth
                name="link"
                value={formData.link}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Icon"
                variant="outlined"
                fullWidth
                name="icon"
                value={formData.icon}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Scheduled Time"
                  variant="standard"
                  type="datetime-local"
                  fullWidth
                  name="scheduled_time"
                  value={formData.scheduled_time}
                  onChange={handleChange}
                />

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  value={formData.scheduled_time || new Date()}
                  onChange={handleChange}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date() + 30}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      aria-describedby="outlined-weight-helper-text"
                      className="clsure-datefield"
                      variant="standard"
                    />
                  )}
                  componentsProps={{
                    actionBar: {
                      actions: ["today"],
                    },
                  }}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  );
}

export default PushNotificationForm;
