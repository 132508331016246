import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';

import { Avatar } from '@mui/material';
  import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone';
  import ChevronRightIcon from '@mui/icons-material/ChevronRight';
  import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

const DrawerHeaders = () => {
    const theme = useTheme();
    return (
        <DrawerHeader  sx={{ justifyContent: "space-between" }}>
            <IconButton sx={{ p: 0 }}>
                <Avatar >
                    <PermIdentityTwoToneIcon />
                </Avatar>
            </IconButton>
            <IconButton >
                {theme.direction === 'rtl' ? (
                    <ChevronRightIcon />
                ) : (
                    <ChevronLeftIcon />
                )}
            </IconButton>
        </DrawerHeader>
    )
};

export default DrawerHeaders