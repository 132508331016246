import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { post } from "../../../../ApiHelper";
import Layout from "../../../layouts/Layout";
import { formatdate } from "../../../../Utility";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function PushNotificationList() {
  const [msgAlert, setMsgAlert] = useState({
    message: undefined,
    status: undefined,
  });
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    sortField: "scheduled_time",
    sortOrder: "desc",
    isactive: 0,
    startdate: "",
    enddate: "",
  });

  useEffect(() => {
    fetchNotifications();
  }, [filters]);

  const fetchNotifications = async () => {
    try {
      const response = await post(
        `${global.appUrl}/crmapis/getpushnotifications`,
        filters
      );
      if (response.message === "success") {
        setMsgAlert((prev) => ({
          ...prev,
          message: response.message,
          status: "success",
        }));
        setNotifications(response.data);
      } else {
        console.error("Failed to fetch notifications:", response.data);
        setMsgAlert((prev) => ({
          ...prev,
          message: response.data,
          status: "error",
        }));
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleFilterSubmit = () => {
    fetchNotifications();
  };

    const handleCreatePushNotification = () => {
        navigate("/pushnotification");
    };
        
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2", // Apply alternate row colors
    },
    "&:hover": {
      backgroundColor: "#e0e0e0", // Add hover effect
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#80808052",
      color: "#4e4e4e",
      fontSize: 16, // Increase font size for table headers
      fontWeight: "bold", // Make headers bold
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 6,
      whiteSpace: "nowrap", // Prevent line breaks
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Display ellipsis for overflow
    },
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    width: "80%",
    borderCollapse: "collapse",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: 10,
  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: "#f2f2f2",
  }));

  return (
    <Layout>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField
            label="Start Date"
            type="date"
            name="startdate"
            value={filters.startdate}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="End Date"
            type="date"
            name="enddate"
            value={filters.enddate}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterSubmit}
          >
            Apply Filters
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ marginRight: "15px" }}
            variant="contained"
            color="primary"
            onClick={() =>handleCreatePushNotification()}
          >
            Create Pushnotification
          </Button>
        </Grid>
      </Grid>
      <StyledTable>
        <StyledTableHead>
          <TableRow sx={{ backgroundColor: "#d5d5d5" }}>
            <TableCell>Title</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Scheduled Time</TableCell>
            <TableCell>Status</TableCell>
            {/* Add more headers as needed */}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {notifications.map((notification) => (
            <StyledTableRow key={notification.id}>
              <StyledTableCell>{notification.title}</StyledTableCell>
              <StyledTableCell>{notification.message}</StyledTableCell>
              <StyledTableCell>{notification.link}</StyledTableCell>
              <StyledTableCell>
                {formatdate(notification.scheduled_time)}
              </StyledTableCell>
              <StyledTableCell>
                {notification.isactive.toString()}
              </StyledTableCell>
              {/* Add more cells as needed */}
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Layout>
  );
}

export default PushNotificationList;
