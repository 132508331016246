import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { post } from "../../../ApiHelper";
import Layout from "../../layouts/Layout";
import { formatdate } from "../../../Utility";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function FranchaiseeDetails() {
  const [msgAlert, setMsgAlert] = useState({
    message: undefined,
    status: undefined,
  });
  const navigate = useNavigate();
  const [franchaisees, setFranchaisees] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    isactive: true,
    startdate: "",
    enddate: "",
  });

  useEffect(() => {
    fetchFranchisees();
  }, [filters]);

  const fetchFranchisees = async () => {
    try {
      const response = await post(
        `${global.appUrl}/crmapis/getfranchisees`,
        filters
      );
      if (response.message === "success") {
        setMsgAlert((prev) => ({
          ...prev,
          message: response.message,
          status: "success",
        }));
        console.log(response.data);
        setFranchaisees(response.data.franchisees);
      } else {
        console.error("Failed to fetch franchisees:", response.data);
        setMsgAlert((prev) => ({
          ...prev,
          message: response.data,
          status: "error",
        }));
      }
    } catch (error) {
      console.error("Error fetching franchisees:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleFilterSubmit = () => {
    fetchFranchisees();
  };

  const handleCreatePushNotification = () => {
    navigate("/createfranchaisee");
  };

  const handleRowClick=(data)=>{
    navigate("/createfranchaisee",{state:{data}});
  }
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2", // Apply alternate row colors
    },
    "&:hover": {
      backgroundColor: "#e0e0e0", // Add hover effect
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#80808052",
      color: "#4e4e4e",
      fontSize: 16, // Increase font size for table headers
      fontWeight: "bold", // Make headers bold
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 6,
      whiteSpace: "nowrap", // Prevent line breaks
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Display ellipsis for overflow
    },
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    width: "80%",
    borderCollapse: "collapse",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: 10,
  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: "#f2f2f2",
  }));

  return (
    <Layout>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField
            label="Start Date"
            type="date"
            name="startdate"
            value={filters.startdate}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="End Date"
            type="date"
            name="enddate"
            value={filters.enddate}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterSubmit}
          >
            Apply Filters
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ marginRight: "15px" }}
            variant="contained"
            color="primary"
            onClick={() => handleCreatePushNotification()}
          >
            Create Franchaisee
          </Button>
        </Grid>
      </Grid>
      <StyledTable>
        <StyledTableHead>
          <TableRow sx={{ backgroundColor: "#d5d5d5" }}>
            <TableCell>Name</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>City</TableCell>
            <TableCell>District</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Deposit</TableCell>
            <TableCell>Is Active</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Updated Date</TableCell>
            {/* Add more headers as needed */}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {franchaisees.map((franchaisee) => (
            <StyledTableRow key={franchaisee.franchisee_id}  onClick={() => handleRowClick(franchaisee)}>
              <StyledTableCell>{franchaisee.name}</StyledTableCell>
              <StyledTableCell>{franchaisee.owner}</StyledTableCell>
              <StyledTableCell>{franchaisee.city}</StyledTableCell>
              <StyledTableCell>{franchaisee.district}</StyledTableCell>
              <StyledTableCell>{franchaisee.state}</StyledTableCell>
              <StyledTableCell>{franchaisee.phone}</StyledTableCell>
              <StyledTableCell>{franchaisee.email}</StyledTableCell>
              <StyledTableCell>{franchaisee.deposit}</StyledTableCell>
              <StyledTableCell>{franchaisee.isactive.toString()}</StyledTableCell>
              <StyledTableCell>
                {formatdate(franchaisee.created_at)}
              </StyledTableCell>
              <StyledTableCell>
                {formatdate(franchaisee.updated_at)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Layout>
  );
}

export default FranchaiseeDetails;
