import { lazy } from "react";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import RoomSharpIcon from "@mui/icons-material/RoomSharp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import * as actions from "../components/pages/commenServices/Services";
import NoPageFound from "../components/login/NoPageFound";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CampaignIcon from '@mui/icons-material/Campaign';
import PushNotificationForm from "../components/pages/ManageMisc/PushNotificationPage/PushNotifications";
import PushNotificationList from "../components/pages/ManageMisc/PushNotificationPage/PushNotificationList";
import LeadDetails from "../components/pages/Loan_leads/LeadDetails";
import GroupsIcon from '@mui/icons-material/Groups';
import FranchaiseeDetails from "../components/pages/FranchaiseePages/FranchaiseeDetails";
import CreateFranchaise from "../components/pages/FranchaiseePages/CreateFranchaise";
//import BugReportIcon from '@mui/icons-material/BugReport';
const Dashboard = lazy(() => import("../components/pages/dashboard/Dashboard"));
const Stores = lazy(() => import("../components/pages/store/Stores"));
const Customer = lazy(() =>
  import("../components/pages/CustomerScreen/Customer")
);
const Login = lazy(() => import("../components/login/Login"));
// const ServiceDetails = lazy(() =>
//   import("../components/pages/seviceRequest/ServiceDetails")
// );
const ManageMisc = lazy(() =>
  import("../components/pages/ManageMisc/ManageMisc")
);
const ViewCommentDetails = lazy(() =>
  import("../components/pages/store/ViewCommentDetails")
);
//const ErrorLog = lazy(() => import("../components/pages/ErrorLog/Logs"));
const AccessControl = lazy(() =>
  import("../components/pages/AccessControl/AccessControl")
);
const CancelledJobs = lazy(() =>
  import("../components/pages/cancelledJobs/CancelledJobs")
);
const TicketPage = lazy(() =>
  import("../components/pages/TicketScreen/TicketPage")
);
const ImageViewPage = lazy(() =>
  import("../components/pages/cancelledJobs/ImageViewPage")
);
const Rating = lazy(() => import("../components/pages/RatingComponent/Rating"));
const AvgRating = lazy(() =>
  import("../components/pages/RatingComponent/AvgRating")
);
const FuelReport = lazy(() =>
  import("../components/pages/Fuel_Info/Fuelreport")
);
const Tracking = lazy(() => import("../components/pages/Tracking/Tracking"));
const PageUnderCon = lazy(() => import("../components/login/PageUnderCon"));
const Loanleads= lazy(() => import("../components/pages/Loan_leads/Loanleads"));

// const isPermissionForService = actions.hasPermission("assembly-request", "r");
// const isPermissionForDashboard = actions.hasPermission("assembly dashboard", "r");
// const isPermissionForTechnician = actions.hasPermission("technician", "r");
// const isPermissionForClosureScreen = actions.hasPermission("closure-screen", "r");
// const isPermissionForCancelledJob = actions.hasPermission("cancelled-jobs", "r");
// const isPermissionForRating = actions.hasPermission("/rating/all-rating", "r");
// const isPermissionForFuelReport = actions.hasPermission("/fuel_info/fuelreport", "r");
// const isPermissionForAvgRating = actions.hasPermission("/rating/avg-rating", "r");
// const isPermissionForTracking = actions.hasPermission("/tracking", "r");

const Route = [
  {
    title: "Login",
    path: "/",
    showInMenu: false,
    role: "all",
    icon: <DashboardCustomizeIcon />,
    component: <Login />,
  },
  //isPermissionForDashboard && {
  {
    title: "Dashboard",
    path: "/dashboard",
    showInMenu: true,
    role: "all",
    icon: <DashboardCustomizeIcon />,
    component: <Dashboard />,
  },
  //isPermissionForService && {
  {
    title: "Stores",
    path: "/stores",
    showInMenu: true,
    role: "all",
    icon: <StorefrontIcon />,
    component: <Stores />,
  },
  // isPermissionForTechnician && {
  {
    title: "Customer",
    path: "/customer",
    showInMenu: true,
    role: "all",
    icon: <PeopleAltIcon />,
    component: <Customer />,
  },

  // {
  //   title: "Assembly request Details",
  //   path: "/assembly-request/serviceDetail/:serviceID",
  //   showInMenu: false,
  //   role: "all",
  //   icon: <EngineeringIcon />,
  //   component: <ServiceDetails />,
  // },
  {
    title: "Assembly request  comment Details",
    path: "/assembly-request/serviceDetail/comment/:serviceID",
    showInMenu: false,
    role: "all",
    icon: <EngineeringIcon />,
    component: <ViewCommentDetails />,
  },
  // isPermissionForClosureScreen && {
  {
    title: "Access Control",
    path: "/accesscontrol",
    showInMenu: true,
    role: "all",
    icon: <ManageAccountsIcon />,
    component: <AccessControl />,
  },
  {
    title: "Tickets",
    path: "/ticketpage",
    showInMenu: true,
    role: "all",
    icon: <LocalActivityIcon />,
    component: <TicketPage />,
  },
  {
    title: "Loan leads",
    path: "/loanleads",
    showInMenu: true,
    role: "all",
    icon: <AccountBalanceIcon />,
    component: <Loanleads />,
  },
  // isPermissionForCancelledJob && {
  //   {title: "Error Logs",
  //   path: "/logs",
  //   showInMenu: true,
  //   role: "all",
  //   icon: <BugReportIcon />,
  //   component: <ErrorLog />,
  // },
  {
    title: "Image View Page",
    path: "/url/:imgUrl",
    showInMenu: false,
    role: "all",
    icon: <HighlightOffIcon />,
    component: <ImageViewPage />,
  },
  // isPermissionForRating && {
  {
    title: "Rating",
    path: "/rating/all-rating",
    showInMenu: false,
    role: "all",
    icon: <ThumbsUpDownIcon />,
    component: <Rating />,
  },
  // isPermissionForAvgRating && {
  {
    title: "rating",
    path: "/rating/avg-rating",
    showInMenu: false,
    role: "all",
    icon: <ThumbsUpDownIcon />,
    component: <AvgRating />,
  },
  // isPermissionForFuelReport && {
  {
    title: "Manage Misc",
    path: "/managemisc",
    showInMenu: true,
    role: "all",
    icon: <LocalGasStationIcon />,
    component: <ManageMisc />,
  },
  // isPermissionForTracking && {
  {
    title: "Tracking",
    path: "/Tracking",
    showInMenu: false,
    role: "all",
    icon: <RoomSharpIcon />,
    component: <Tracking />,
  },
  {
    title: "puc",
    path: "/page-under-constraction",
    showInMenu: false,
    role: "all",
    icon: <ThumbsUpDownIcon />,
    component: <PageUnderCon />,
  },
  {
    title: "NoPageFound",
    path: "*",
    showInMenu: false,
    role: "all",
    icon: <DashboardCustomizeIcon />,
    component: <NoPageFound />,
  },
  {
    title:"pushnotification",
    path:"/pushnotification",
    showInMenu:false,
    role:"all",
    icon:<CampaignIcon />,
    component:<PushNotificationForm />
  },
  {
    title:"Pushnotification List",
    path:"/pushnotificationlist",
    showInMenu:true,
    role:"all",
    icon:<CampaignIcon />,
    component:<PushNotificationList />

  },
  {
    title:'Lead Deatails',
    path:'/lead-details',
    showInMenu:false,
    role:'all',
    icon:<CampaignIcon />,
    component:<LeadDetails />
  },
  {
    title:'franchisee',
    path:'/franchaisee',
    showInMenu:true,
    role:'all',
    icon:<GroupsIcon />,
    component:<FranchaiseeDetails />
  },
  {
    title:'franchisee',
    path:'/createfranchaisee',
    showInMenu:false,
    role:'all',
    icon:<GroupsIcon />,
    component:<CreateFranchaise />
  }
];

export default Route;
