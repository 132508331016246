import React from 'react';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Routes from '../../../routes/Route';
import { ListItemAvatar } from '@mui/material';
// import { useTheme } from '@mui/styles';

import { useTheme } from '@mui/material/styles';
const SidebarMenu = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const theme = useTheme();
  const handleListItemClick = (e, index) =>{
    setSelectedIndex(index)
  }
  return (
    <>
      <List >
        {Routes.map(
          (route, index) =>
            route.showInMenu && (
              <NavLink
                key={index}
                to={route.path}
                style={({ isActive }) => ({ color: isActive ? theme.palette.primary.main : "rgb(84 82 84 / 71%)",backgroundColor:isActive ?"#0e0af3":"rgb(84 82 84 / 71%)" })}
              >
                <ListItem
                  button
                  key={route.title + index}
                  onClick={(event) => handleListItemClick(event, index)}>
                  <ListItemAvatar>{route.icon}</ListItemAvatar>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>
            )
        )}
      </List>
    </>
  );
};

export default SidebarMenu;
