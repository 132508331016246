import { Box } from '@mui/material';
import React from 'react';
import SideBar from '../pages/commenPages/SideBar';
import DrawerHeaders from '../pages/commenPages/DrawerHeader';

const Layout = ({ children,msgAlert }) => {
  return (
    <Box sx={{ display: "flex",backgroundColor:'white' }}>
    <SideBar msgAlert={msgAlert} />
    <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
      <DrawerHeaders />
    { children }
    
    </Box>
  </Box>

  );
};

export default Layout;
