import React, { useEffect, useState } from "react";
import { post } from "../../../ApiHelper";
import { useLocation } from "react-router";
import { formatdate, getrandomcoler } from "../../../Utility";
import SideBar from "../commenPages/SideBar";
import DrawerHeaders from "../commenPages/DrawerHeader";
import { Box, Button, Menu, MenuItem, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const statusMap = {
  0: "Yet to Call",
  1: "Suspect",
  2: "Prospect",
  3: "Not Interested",
  4: "Not Reachable",
  5: "Not Eligible",
  6: "Interested",
  7: "Applied",
  8: "Disbursed",
  9: "Rejected",
  10: "Closed",
  11: "Not Applied",
};

function LeadInfo({ lead, setOpenEditForm, openEditForm }) {
 
  const stylecard = {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    padding: 10,
  };

  const labelcss = {
    fontWeight: "bold",
  };

  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        flexDirection: "column",
        flexWrap: "wrap",
        margin: "auto",
        height: "300px",
        boxShadow: "0px 0px 10px 0px gray",
        borderRadius: "10px",
        padding: 10,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={stylecard}>
          <div style={labelcss}>Customer Name:</div> {lead?.username}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Email:</div> {lead?.email}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Mobile:</div> {lead?.mobile}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Loan Amount:</div>
          {`₹ ${lead?.loan_amount}`}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Status:</div>
          {statusMap[lead?.status]}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={stylecard}>
          <div style={labelcss}>Lead ID:</div> {lead?.lead_id}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Created At:</div>
          {formatdate(lead?.created_at)}
        </div>
        <div style={stylecard}>
          <div style={labelcss}>Updated At:</div>
          {formatdate(lead?.updated_at)}
        </div>
      </div>
      <Button
        variant="outlined"
        style={{ margin: 10, width: "fit-content" }}
        startIcon={<EditIcon />}
        onClick={() => setOpenEditForm(!openEditForm)}
      >
        Edit
      </Button>
    </div>
  );
}
const EditForm = ({ setMsgAlert, fetchLeadData,setOpenEditForm,LeadAmount }) => {
  const [status, setStatus] = useState(0);
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [Amount, setAmount] = useState(LeadAmount);

  const updateLead = async () => {
    if (comment && status && Amount) {
      try {
        const response = await post(`${global.appUrl}/crmapis/updateloanlead`, {
          lead_id: location?.state.lead_id,
          status: status,
          comment: comment,
          amount: Amount,
        });
        if (response?.message === "success") {
          setMsgAlert({
            message: "Lead status updated successfully",
            status: "success",
          });
          setOpenEditForm(false);
          fetchLeadData();
        }
      } catch (error) {
        setMsgAlert({
          message: "Error updating lead status",
          status: "error",
        });
      }
    } else {
      if (!comment) {
        setMsgAlert({
          message: "Comment is required",
          status: "error",
        });
      } else if (!Amount) {
        setMsgAlert({
          message: "Amount is required",
          status: "error",
        });
      } else if (!status) {
        setMsgAlert({
          message: "Status is required",
          status: "error",
        });
      }
    }
  };
  return (
    <div
      style={{
        border: "1px solid #000",
        borderRadius: 10,
        padding: 10,
        width: "80%",
        margin: "auto",
        marginTop: 20,
        boxShadow: "0px 0px 10px 0px gray",
      }}
    >
      <h3> Edit Form</h3>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          flexDirection: "column",
        }}
      >
        <TextField
          id="outlined-select-currency-native"
          select
          size="medium"
          label="Change Status"
          variant="outlined"
          className="form-text4"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          SelectProps={{
            native: true,
          }}
        >
          {Object.keys(statusMap).map((key) => (
            <option key={key} value={key}>
              {statusMap[key]}
            </option>
          ))}
        </TextField>
        <TextField
          id="outlined-basic"
          label="Amount"
          value={Amount}
          onChange={(e) => setAmount(e.target.value)}
          variant="outlined"
        />
        <TextField
          id="outlined-basic"
          label="Comment"
          multiline
          variant="outlined"
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Box>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Button
          onClick={() => updateLead()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button
        onClick={() => setOpenEditForm(false) }
        variant="outlined" color="primary" style={{ marginLeft: 10 }}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

function LogDetails({ log }) {
  const labelcss = {
    fontWeight: "bold",
  };
  const stylecard = {
    display: "flex",
    gap: 10,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        margin: "auto",
        marginTop: 20,
      }}
    >
      {log?.map((entry) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            margin: "auto",
            padding: 10,
            backgroundColor: "whitesmoke",
            flexDirection: "column",
            boxShadow: "0px 0px 10px 0px gray",
            borderRadius: "10px",
            borderLeft: `5px solid ${getrandomcoler()}`,
          }}
          key={entry.id}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={stylecard}>
              <div style={labelcss}>Status:</div>
              {statusMap[entry.status]}
            </div>
            <div style={stylecard}>
              <div style={labelcss}>Created At:</div>
              {formatdate(entry.created_at)}
            </div>
            <div style={stylecard}>
              <div style={labelcss}>Created By:</div>
              {entry.created_by}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "grey",
              margin: "auto",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: 10,
              alignSelf: "flex-start",
              gap: 10,
            }}
          >
            <div style={labelcss}>Comment:</div>
            <div>{entry.comment}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

function LeadDetails() {
  const location = useLocation();
  const [leadData, setLeadData] = useState({});
  const [msgAlert, setMsgAlert] = useState({
    message: undefined,
    status: undefined,
  });
  const [openEditForm, setOpenEditForm] = useState(false);
  useEffect(() => {
    fetchLeadData();
  }, []);
  const fetchLeadData = async () => {
    try {
      const response = await post(
        `${global.appUrl}/crmapis/getloanleaddetails`,
        { lead_id: location?.state.lead_id }
      );
      if (response?.message === "success" && response.data) {
        setLeadData(response.data);
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
    }
  };
  const { lead, log } = leadData;

  return (
    <Box sx={{ display: "flex" }}>
      <SideBar msgAlert={msgAlert} />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeaders />
        <LeadInfo
          lead={lead}
          openEditForm={openEditForm}
          setOpenEditForm={setOpenEditForm}
        />
        {openEditForm && (
          <EditForm
            msgAlert={msgAlert}
            setMsgAlert={setMsgAlert}
            fetchLeadData={fetchLeadData}
            setOpenEditForm={setOpenEditForm}
            LeadAmount={lead.loan_amount}
          />
        )}
        <LogDetails log={log} />
      </Box>
    </Box>
  );
}

export default LeadDetails;
