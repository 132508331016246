import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { post } from "../../../ApiHelper";
import Layout from "../../layouts/Layout";
import { useLocation } from "react-router-dom";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function CreateFranchaise() {
  const [msgAlert, setMsgAlert] = useState({
    message: undefined,
    status: undefined,
  });
  const location = useLocation();
  console.log(location);
  const [formData, setFormData] = useState({
    franchisee_id: location?.state?.data?.franchisee_id || "",
    name: location?.state?.data?.name || "",
    owner: location?.state?.data?.owner || "",
    city: location?.state?.data?.city || "",
    state: location?.state?.data?.state || "",
    phone: location?.state?.data?.phone || "",
    email: location?.state?.data?.email || "",
    district: location?.state?.data?.district || "",
    pincode: location?.state?.data?.pincode || "",
    address: location?.state?.data?.address || "",
    deposit: location?.state?.data?.deposit || "",
    isactive: location?.state?.data?.isactive || false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   if (!formData.franchisee_id) {
     try {
      delete formData.franchisee_id;
      delete formData.isactive;
       const response = await post(
         `${global.appUrl}/crmapis/createfranchisee`,
         formData
       );
       console.log(response);
       // const response = await axios.post('/api/push-notifications', formData);
 
       if (response?.message === "success" && response.data) {
         setMsgAlert((prev) => ({
           ...prev,
           message: response.message,
           status: "success",
         }));
       } else {
         setMsgAlert((prev) => ({
           ...prev,
           message: JSON.stringify(response?.data),
           status: "error",
         }));
       }
     } catch (error) {
       console.error("Error saving franchisee:", error);
       // Handle error, e.g., show an error message
     }
   }
   else{
    try {
      const response = await post(
        `${global.appUrl}/crmapis/updatefranchisee`,
        formData
      );
      console.log(response);
      // const response = await axios.post('/api/push-notifications', formData);

      if (response?.message === "success" && response.data) {
        setMsgAlert((prev) => ({
          ...prev,
          message: response.message,
          status: "success",
        }));
      } else {
        setMsgAlert((prev) => ({
          ...prev,
          message: JSON.stringify(response?.data),
          status: "error",
        }));
      }
    } catch (error) {
      console.error("Error updating franchisee:", error);
      // Handle error, e.g., show an error message
    }
   }


  };
  return (
    <Layout msgAlert={msgAlert}>
      <Container maxWidth="sm">
        <Typography variant="h5" align="center" gutterBottom>
          {(formData?.franchisee_id ? "Update" : "Create") +" Franchise"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Owner"
                variant="outlined"
                fullWidth
                name="owner"
                value={formData.owner}
                onChange={handleChange}
              />
            </Grid>
            <Grid sx={{ display: "flex", gap: 3 }} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", gap: 3 }} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", gap: 3 }} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="District"
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Deposit"
                variant="outlined"
                fullWidth
                name="deposit"
                value={formData.deposit}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                label="Icon"
                variant="outlined"
                fullWidth
                name="icon"
                value={formData.icon}
                onChange={handleChange}
              /> */}
              <FormControlLabel
                control={
                  <Switch
                    name={"isactive"}
                    checked={formData.isactive}
                    onChange={(e) => {
                      e.target.value = e.target.checked;
                      handleChange(e);
                    }}
                  />
                }
                size="small"
                // labelPlacement="bottom"
                label={formData.isactive ? "Active" : "In Active"}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {formData?.franchisee_id?"Update":"Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  );
}

export default CreateFranchaise;
