import React, { Suspense } from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import SiteRoutes from './routes/Route';
import Protected from './routes/Protected';
import config from "./config";
import ErrorBoundary from './components/errorBoundary/ErrorBoundary'
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';
global.ftk =  localStorage.getItem('appToken') ? localStorage.getItem('appToken') :undefined
global.appUrl=config.BASE_URL;

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Suspense fallback={<div style={{margin:"auto", top: 0,bottom: 0,  left: 0, right: 0,position: "absolute", width: 20,  height: 20}}><CircularProgress /></div>}>
          <Routes>
            {SiteRoutes?.map((route, i) => {
                return (
                  <Route
                    key={route.title}
                    exact={route.path === '/' ? true : false}
                    path={route.path}
                    element={<Protected Componant={route.component} />}
                  ></Route>
                );
              })}
          </Routes>
        </Suspense>
        </ErrorBoundary>
        </ThemeProvider>
    </div>
  );
}
export default App;
