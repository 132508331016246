import axios from "axios";

const token = localStorage.getItem("appToken");

export const get = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        return error;
    }
}
export const post = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const postimage = async (url, data) => {
    try {
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        const response = await axios.post(url, data,headers);
        return response.data;
    } catch (error) {
        return error;
    }
}

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("error", error.response.status);
        if (error.response.status === 401) {
            localStorage.removeItem("appToken");
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("appToken");
        console.log("token", token);
        config.headers.Authorization = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);